<template>
  <div>
    <cardless-base>
      <PageTitle :title="`Troca de detergente enzimático`" />
    </cardless-base>
    <Modal
      id="authModal"
      ref="authModal"
      v-model="confirmShow"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      centered
    >
    <template #modal-header>
      <div class="header">
        <x-icon class="close-icon" @click="cancel()" />
      </div>
    </template>
      <div class="auth">
        <h3>Validar usuário responsável</h3>
      </div>
      <br />

      <b-form-group label="Usuário">
        <b-form-input
          id="registro"
          class="invision-input complement-input"
          v-model="auth.registro"
          autocomplete="off"
          name="registro"
          required
        />
      </b-form-group>
      <InputSenha v-model="auth.password"/>

      <br />

      <button type="button" class="auth-button" @click="authenticate()">
        OK
      </button>
    </Modal>
    <b-row>
      <b-col cols="4" class="first-column">
        <div class="main-content-trocas">
          <b-row>
            <b-col class="tittle-trocas w-100"> Últimas trocas </b-col>
          </b-row>
        </div>
        <div
          :class="!hasNotLastExchanges() ? null : 'main-content-equipamentos'"
          v-for="equipamento in lastExchanges"
          v-bind:key="equipamento.nome_equipamento"
        >
          <b-row v-if="equipamento.lote_detergente">
            <b-col cols="2">
              <img class="icon" src="../../assets/img/speaker.svg" />
            </b-col>
            <b-col cols="10">
              <b-row>
                <b-col class="w-100">
                  <div>
                    <b-row>
                      <b-col
                        class="informacoes-equipamentos tittle-equipamento"
                      >
                        {{ equipamento.equipamento }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="informacoes-equipamentos">
                        {{ equipamento.data_troca }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="informacoes-equipamentos">
                        às {{ equipamento.horario }} horas
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="8" class="second-column">
        <div class="main-form">
          <h2 class="mb-5">Troca de detergente enzimático</h2>
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group>
                <label>Equipamento</label>
                <GenericSelect
                  name="equipamento"
                  labelKey="nome"
                  v-model="model.id_equipamento"
                  :customFilters="equipamentoEsterilizacaoFilters"
                  route="equipamento"
                ></GenericSelect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group>
                <label>Novo lote de detergente</label>
                <b-form-input
                  v-model="model.lote_detergente"
                  autocomplete="off"
                  class="invision-input sm"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label>Data da troca</label>
                <b-form-datepicker
                  id="validade"
                  placeholder="Data da troca"
                  v-model="model.data"
                  name="validade"
                  locale="pt"
                  size="md"
                >
                </b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label>Horário</label>
                <Hour
                  :isBusy="false"
                  size="md"
                  v-model="model.horario"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="btn-confirmar">
            <b-col>
              <b-button
                type="button"
                variant="none"
                @click="save"
                class="ml-1 mr-2 mt-4 primary-contained-button"
                >CONFIRMAR</b-button
              >
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import PageTitle from '@/templates/PageTitle';
import CardlessBase from '@/templates/CardlessBase';
import GenericSelect from '@/components/Form/GenericSelect';
import Modal from '@/components/Utils/Modal';
import InputSenha from '@/components/Form/InputSenha';
import GenericApi from '@/services/genericRequest';
import RestResourceUsuario from '@/services/usuario';
import Hour from '../../components/Utils/Hour';

export default {
  components: {
    PageTitle,
    CardlessBase,
    GenericSelect,
    Hour,
    InputSenha,
    Modal,
  },

  props: {
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    apiFunction: {
      type: String,
      required: false,
      default() {
        return 'getWithoutPagination';
      },
    },
  },

  computed: {
    ...mapState({
      currentUnidade: (state) => state.currentUnidade,
    }),
    equipamentoEsterilizacaoFilters() {
      let idRetorno = 0;
      if (this.currentUnidade.id_unidade) {
        idRetorno = this.currentUnidade.id_unidade;
      }

      return {
        id_unidade: idRetorno,
        lavagem_automatizada: true,
      };
    },
  },

  data() {
    return {
      auth: {
        registro: '',
        password: '',
        permission: 'rw_material',
      },
      model: {
        id_equipamento: null,
        lote_detergente: '',
        data: moment().format('YYYY-MM-DD'),
        horario: '',
      },
      lastExchanges: [],

      route: 'loteDetergente',
      previousRoute: 'loteDetergente',
      confirmShow: true,
    };
  },

  mounted() {
    this.fillLastExchanges();
  },

  methods: {
    cancel() {
      this.$bvModal.hide('authModal');
      this.$router.replace('/loteDetergente/');
    },
    hasNotLastExchanges() {
      const result = this.lastExchanges.filter((el) => typeof el.lote_detergente === 'string');

      if (result.length) {
        return true;
      }

      return false;
    },
    validateState() {},
    async authenticate() {
      if (!this.auth.registro || !this.auth.password) return;

      const result = await RestResourceUsuario.authUserByLoginProps(
        this.auth,
      );

      if (result.length) {
        this.auth.password = '';
        this.$refs.authModal.hide();
      } else {
        swal({
          title: 'Usuário não autenticado',
          text:
            'Registro ou senha incorretos ou não possui permissão para realizar essa ação',
          icon: 'error',
          button: 'Continuar',
        });
      }
    },
    async save() {
      const [h, m] = this.model.horario.split(':');
      this.model.data_troca = moment(this.model.data).set({ h, m }).format();
      try {
        await this.api.create(this.model, this.route);

        await swal({
          title: 'Bom trabalho!',
          text: 'O registro foi criado na base de dados com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });
        if (this.previousRoute) {
          this.$router.replace({ name: this.previousRoute });
        }
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            errorMessage || 'Ocorreu um problema ao tentar criar o registro',
          icon: 'error',
          button: 'Continuar',
        });
      }
    },

    async fillLastExchanges() {
      const result = await this.api[this.apiFunction](
        {},
        `loteDetergente/lastTrocasByUnidade/${this.currentUnidade.id_unidade}`,
      );

      if (result) {
        this.lastExchanges = result;
      }
    },
  },
};
</script>

<style>
.auth-button {
  width: 100%;
  color: white;
  padding: 5px;
  border-radius: 5px;
  background-color: #209f85;
  border: 3px solid #209f85;
}

.text-contained {
  padding: 0 0 0 10px;
  width: 20px;
}

.first-column {
  padding: 0 0 0 0;
}

.second-column {
  padding: 0 0 0 0;
}
.tittle-trocas {
  font-size: 23px;
  margin-bottom: 20px;
}

.main-content-trocas {
  width: 350px !important;
}

.main-content-equipamentos {
  border-radius: 10px;
  background-color: white;
  height: 130px;
  padding: 15px 10px 10px 10px;
  width: 400px;
  margin-bottom: 15px;
}

.informacoes-equipamentos {
  margin-bottom: 1px;
  margin-top: 3px;
}

.icon {
  padding: 10px 20px;
}

.tittle-equipamento {
  font-size: 20px;
  font-weight: bold;
}

.equipamentos-sem-troca {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 10px;
  position: relative;
  top: 10px;
  left: 10px;
  width: 750px;
  margin-left: 30px;
}

.text {
  position: relative;
  top: 20%;
  padding: 0 0 0 0;
}

.close-button {
  border-radius: 5px;
  border-color: #209f85;
  border-width: 100px;
  color: #209f85;
  border: 3px solid #209f85;
}

.close-button:hover {
  color: #209f85;
}

.main-form {
  border-radius: 15px;
  background-color: white;
  height: 480px;
  margin-left: 40px;
  width: 750px;
  padding: 35px;
}

.btn-confirmar {
  padding: 0px 0px 0px 500px;
}
.modal-header {
  border-bottom: 0;
}
.header .close-icon {
  position: absolute;
  right: 0;
  top: 0;

  color: #00daae;
  background-color: #EDFEFA;
  border-radius: 50%;
  padding: 2px;
}
.header .modal-logo {
  position: absolute;
  right: 20px;
  top: 20px;
}
.header .close-icon:hover {
  cursor: pointer;
}
.header .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 15px;
  margin-left: 35px;
}
</style>
